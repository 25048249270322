import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { navigate } from 'gatsby'

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
}

class Firebase {
  constructor() {
    if (typeof window !== 'undefined') {
      firebase.initializeApp(config)

      this.auth = firebase.auth()
      this.firestore = firebase.firestore()
      this.functions = firebase.functions()
      this.currentUser = null

      // auth listener
      firebase.auth().onAuthStateChanged(this.verifyAdminUser)
    }
  }

  async verifyAdminUser(user) {
    if (user) {
      const idTokenResult = await user.getIdTokenResult()
      if (idTokenResult.claims.admin) {
        this.currentUser = user
      } else {
        this.signOut()
      }
    } else {
      this.currentUser = null
      navigate('/login')
    }
  }

  isAuthenticated = () => {
    return this.currentUser && this.auth && this.auth.currentUser ? true : false
  }

  async callFunction(name, args) {
    const func = this.functions.httpsCallable(name)
    func(args).then(result => {})
  }

  // async signIn(email, password) {
  //   this.context.Firebase.auth
  //     .signInWithEmailAndPassword(email, password)
  //     .then(user => {
  //       console.log('HERE WE ARE!', user)
  //       navigate('/')
  //       return true
  //     })
  //     .catch(e => {
  //       console.log('ERROR!', e)
  //       return e
  //     })
  // }

  signOut = () => {
    this.auth.signOut().then(() => {
      navigate('/')
    })
  }
  getObjectFromDataAndRef = (data, ref) => {
    // returns a standardized JSON object for use in the application
    return {
      ...data,
      ...{
        docID: ref.id,
        path: ref.path,
        parent: ref.parent.parent
          ? { docID: ref.parent.parent.id, path: ref.parent.parent.path }
          : null,
      },
    }
  }

  // storageHelper.subCollectionFetch('drawings', { where: ["name", "==", "Cedar Falls, Iowa"] })
  subCollectionFetch = async (collection, args = {}) => {
    return this.fetch(collection, { ...args, collectionGroup: true })
  }

  // storageHelper.fetch('collection_name', 'document ID')
  // OR
  // storageHelper.fetch('collection_name', { where: ["capital", "==", true] })
  //
  // will fetch EITHER a collection OR a document (if docID is provided) from Firebase
  fetch = async (collection, args) => {
    const docID = typeof args == 'string' ? args : null

    try {
      // lookup document by docID

      if (docID) {
        const doc = await this.firestore
          .collection(collection)
          .doc(docID)
          .get()
        if (doc.exists) {
          // normalize our data (always set the docID)
          return this.getObjectFromDataAndRef(doc.data(), doc.ref)
        } else {
          return null
        }
      } else {
        let ref =
          args && args.collectionGroup
            ? this.firestore.collectionGroup(collection)
            : this.firestore.collection(collection)
        // add query conditions
        if (args) {
          if (args.where) {
            args.where.forEach(w => {
              ref = ref.where(...w)
            })
          }
          if (args.orderBy) {
            args.orderBy.forEach(o => {
              ref = ref.orderBy(...o)
            })
          }
          if (args.limit) {
            ref = ref.limit(args.limit)
          }
        }

        // fetch an entire collection
        const snapshot = await ref.get()

        let data = []
        snapshot.docs.forEach(doc => {
          data.push(this.getObjectFromDataAndRef(doc.data(), doc.ref))
        })
        return data
      }
    } catch (error) {
      console.log('Could not fetch record.', error)
    }
  }
}
export default new Firebase()

/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Breadcrumbs from '../components/Breadcrumbs'

import Firebase from '../utilities/Firebase'
import Header from '../components/header'

import '../scss/application.scss'

export const SessionContext = React.createContext({
  Firebase,
})

const Layout = props => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        // TODO: merge props.meta & defaults
        return (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: data.site.siteMetadata.description,
                },
              ]}
              link={[
                { rel: 'sortcut icon', type: 'image/png', href: '/favicon.png' },
                { rel: 'apple-touch-icon', href: '/webclip.jpg' },
                {
                  rel: 'stylesheet',
                  href:
                    'https://fonts.googleapis.com/css?family=Montserrat:300,300i,500,500i,900,900i&display=swap',
                },
              ]}
              script={[
                {
                  src: 'https://www.gstatic.com/firebasejs/7.0.0/firebase-app.js',
                  type: 'text/javascript',
                },
                {
                  src: 'https://www.gstatic.com/firebasejs/7.0.0/firebase-auth.js',
                  type: 'text/javascript',
                },
                {
                  src: 'https://www.gstatic.com/firebasejs/7.0.0/firebase-functions.js',
                  type: 'text/javascript',
                },
              ]}
            >
              <html className={data.htmlClass} lang="en" />
            </Helmet>

            <SessionContext.Provider value={{ Firebase }}>
              <Header />
              <div className="grid-container main">{props.children}</div>
            </SessionContext.Provider>
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

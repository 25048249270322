import React from 'react'

import { SessionContext } from '../components/layout'
import { Link } from 'gatsby'

const Header = props => (
  <SessionContext.Consumer>
    {({ Firebase }) => {
      return (
        <div className="top-bar">
          <div className="top-bar-left">
            <Link to="/">
              <h1 className="logo logo-40 logo-alt">Door Prize</h1>
            </Link>
          </div>
          <div className="top-bar-right">
            <ul className="menu">
              <li>{Firebase.isAuthenticated() ? Firebase.currentUser.email : null}</li>
              <li>
                <button
                  className="button tiny bg-dark-blue"
                  onClick={() => {
                    Firebase.signOut()
                  }}
                >
                  <i className="icon-log-out"></i> Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      )
    }}
  </SessionContext.Consumer>
)

export default Header
